import {useEffect, useMemo, useState} from 'react'
import {LOADING_STATE} from '../reducers/NavReducer'
import {useNavDispatchContext} from '../contexts/NavContext'
import {useAxios} from './useAxios'
import useConfig from './useConfig'
import {routes} from '../apis/routes'

export const useCommonDependencies = () => {
  const navDispatch = useNavDispatchContext()
  const [a2zApi, isApiReady] = useAxios()
  const {dealership} = useConfig()

  const [dealershipData, setDealershipData] = useState(null)
  const [requestTypes, setRequestTypes] = useState(null)

  const [isError, setIsError] = useState(false)
  const [isLangReady, setIsLangReady] = useState(false)

  const {
    dealerships: {
      includes,
      paths: {dealership: dealershipRoute},
    },
  } = routes

  const dealershipPath = useMemo(
    () =>
      dealershipRoute(dealership?.id, [
        includes.ANALYTICS_CONFIGURATION,
        includes.CREDIT_APP_CONFIGURATION,
        includes.DR_SHARE_QUOTE,
        includes.DR_SAVE_DEAL,
        includes.DR_DOC_UPLOADS,
      ]),
    [dealership?.id]
  )

  const setupDealershipData = () => {
    if (!dealership?.id) {
      return
    }

    const dealershipDataStored = localStorage.getItem(dealershipPath)

    if (!dealershipDataStored) {
      return
    }

    setDealershipData(JSON.parse(dealershipDataStored))
  }

  const setupLang = () => {
    const langStored = localStorage.getItem(routes.lang.base)

    if (!langStored) {
      return
    }

    window.A2Z_LANG = JSON.parse(langStored)
    setIsLangReady(true)
  }

  const setupRequestTypes = () => {
    const requestTypeStored = localStorage.getItem(routes.customers.paths.leadsRequestTypes())

    if (!requestTypeStored) {
      return
    }

    setRequestTypes(JSON.parse(requestTypeStored).data)
  }

  const handleFetchError = () => {
    navDispatch({type: 'setLoading', payload: LOADING_STATE.FAILED})
    setIsError(true)
  }

  const handleFetchDealershipData = () => {
    if (dealershipData || !dealership?.id) {
      return
    }

    a2zApi
      .get(dealershipPath)
      .then(({data: dealershipData}) => {
        localStorage.setItem(dealershipPath, JSON.stringify(dealershipData))
        setDealershipData(dealershipData)
      })
      .catch(handleFetchError)
  }

  const handleFetchLang = () => {
    if (isLangReady) {
      return
    }

    a2zApi
      .get(routes.lang.base)
      .then(({data: lang}) => {
        window.A2Z_LANG = lang
        localStorage.setItem(routes.lang.base, JSON.stringify(lang))
        setIsLangReady(true)
      })
      .catch(handleFetchError)
  }

  const handleFetchRequestTypes = () => {
    if (!requestTypes) {
      a2zApi
        .get(routes.customers.paths.leadsRequestTypes())
        .then(({data: requestTypesData}) => {
          localStorage.setItem(
            routes.customers.paths.leadsRequestTypes(),
            JSON.stringify(requestTypesData)
          )
          setRequestTypes(requestTypesData.data)
        })
        .catch(handleFetchError)
    }
  }

  useEffect(() => {
    setupLang()
    setupRequestTypes()
    setupDealershipData()
  }, [dealership?.id])

  useEffect(() => {
    if (!isApiReady) {
      return
    }

    window.onbeforeunload = () => {
      localStorage.clear()
    }

    handleFetchLang()
    handleFetchRequestTypes()
    handleFetchDealershipData()
  }, [isApiReady, dealership?.id])

  return {
    data: {dealershipData, requestTypes},
    isError,
    isLoading: !dealershipData || !isLangReady || !requestTypes,
  }
}
